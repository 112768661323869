$color: (
	typography: (
		1: #FFFFFF,
		2: #FF0000,
		3: #000000,
		4: #F2F2F2,
		5: #f39637,
		6: #404041,
		7: #999999,
		8: #222222





	),
	bg: (
		1: #FFFFFF,
		2: #FF0000,
		3: #000000,
		4: #F2F2F2,
		5: #f39637,
		6: #404041
		

	)
);


$font__family: (
    primary: '"Open Sans", sans-serif',
    base: '"Big Shoulders Display", cursive',
	// secondary: 'GillSansMT',
	// eurostile: '"eurostile",sans-serif',
	// georgia: 'Georgia,Times,Times New Roman,serif'

);


$font__weight: (
	light: 300, 	// font__weight(light)
	regular: 400, 	// font__weight(regular)
	medium: 500,	// font__weight(medium)
	semibold: 600,	// font__weight(semi-bold)
	bold: 700		// font__weight(bold)
);










