.section {
    &-hero {

        .hero {
            &__slider {
                .overlay {
                    background-color: rgba(color(typography, 3), .3);
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                    z-index: 2;
                }
            }
            &-item {
                position: relative;
                img {
                    &.img-100 {
                        @media(max-width: 767px) {
                            height: 400px;
                            object-fit: cover;
                        }
                    }
                }
            }
            &-wrapper {
                display: flex;
                align-items: flex-end;
                padding-top: 50px;
                padding-bottom: 30px;
                color: color(typography, 1);
                position: absolute;
                bottom: 0;
                top: 0;
                height: 100%;
                align-items: center;
                z-index: 5;
                font-size: 25px;
                font-weight: 700;
                @media(max-width: 767px) {
                    font-size: 20px;
                }

                
                h2 {
                    color: color(typography, 5);
                    font-size: 35px;
                    text-transform: uppercase;
                    font-weight: 700;
                    margin-bottom: 22px;
                    @media(max-width: 767px) {
                        font-size: 30px;
                    }
                }
                p {
                    br {
                        @media(max-width: 767px) {
                            display: none;
                        }
                    }
                }

                .btn {
                    background-color: transparent;
                    border: 1px solid color(typography, 1);
                    color: color(typography, 5);
                    border-radius: 0;
                    text-transform: uppercase;
                    padding-left: 30px;
                    padding-right: 30px;
                    font-weight: 600;
                    margin-top: 10px;
                    font-size: 20px;
                    &:hover {
                        background-color: color(typography, 5);
                        border: 1px solid color(typography, 5);
                        color: color(typography, 1);
                    }

                    i {
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}