@charset "UTF-8";
.rotate {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

label {
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out; }

.navbar-toggler span {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

button {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }
  button:after {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease; }

.btn {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }
  .btn:hover {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease; }

a {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }
  a:hover {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease; }

input[type="radio"] + label {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  input[type="radio"] + label::before {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  input[type="radio"] + label::after {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }

input[type="checkbox"] + label {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  input[type="checkbox"] + label:before {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  input[type="checkbox"] + label:after {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
  background: #fff url(../images/ajax-loader.gif) center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url(../fonts/slick.eot);
  src: url(../fonts/slick.eot?#iefix) format("embedded-opentype"), url(../fonts/slick.woff) format("woff"), url(../fonts/slick.ttf) format("truetype"), url(../fonts/slick.svg#slick) format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

body {
  color: #404041;
  background-color: #FFFFFF;
  line-height: 1.6;
  font-size: 15px;
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden; }

.img-100 {
  width: 100%; }

small {
  font-size: 14px; }

b,
strong {
  font-weight: bold; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style: none; }

ul.list-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  ul.list-inline li {
    display: inline-block; }

img {
  display: block;
  max-width: 100%;
  height: auto; }
  img.img-center {
    margin-left: auto;
    margin-right: auto; }
  img.full-width {
    width: 100%; }

a {
  text-decoration: none; }
  a:hover, a:focus {
    text-decoration: none;
    color: inherit; }

.btn {
  text-decoration: none; }
  .btn:hover {
    text-decoration: none; }

a:hover::before, a:hover::after {
  text-decoration: none; }

a:focus {
  outline: none; }

.btn:focus {
  outline: none; }

.btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: inherit; }

p {
  margin: 0 0 1rem 0; }
  p:empty {
    display: none; }
  p:last-child {
    margin-bottom: 0; }

.screen-reader-text {
  display: none; }

h1 {
  font-size: 40px; }

h2 {
  font-size: 30px;
  line-height: 38px; }

h3 {
  font-size: 24px;
  line-height: 30px; }

h4 {
  font-size: 20px;
  line-height: 28px; }

.site-content {
  overflow-x: hidden; }

.row.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

img {
  display: block;
  max-width: 100%;
  height: auto; }
  img .aligncenter {
    float: none;
    margin: 0 auto 20px; }
  img .alignright {
    float: right;
    margin: 0 0 20px 20px; }
  img .alignleft {
    float: left;
    margin: 0 20px 20px 0; }

.slick-slide:focus {
  outline: none; }

.btn {
  border-radius: 0;
  text-transform: uppercase;
  font-size: 15px;
  padding: 10px 30px; }
  .btn-yellow {
    background-color: #f39637;
    color: #FFFFFF;
    font-weight: 700;
    border: 1px solid #f39637; }
    .btn-yellow:hover {
      background-color: #FFFFFF;
      color: #404041;
      border: 1px solid #404041; }
  .btn-white {
    background-color: #FFFFFF;
    color: #404041;
    border: 1px solid #404041;
    font-weight: 600;
    margin-top: 26px; }
    .btn-white:hover {
      background-color: #f39637;
      color: #FFFFFF;
      border: 1px solid #f39637; }

.header-site {
  position: relative;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); }

.header-top {
  background-color: #F2F2F2;
  color: #404041;
  font-size: 13px;
  line-height: 28px;
  position: relative; }
  @media (min-width: 1200px) {
    .header-top:before, .header-top:after {
      position: absolute;
      content: '';
      background-color: #ffffff; } }
  @media (min-width: 1200px) {
    .header-top:before {
      bottom: -1px;
      right: 0;
      height: 1.5625rem;
      width: calc(50% - 470px - .875rem);
      width: calc(50% - 570px - .875rem);
      border-top: 1px solid #eeeeee; } }
  @media (min-width: 1200px) {
    .header-top:after {
      bottom: -1.375rem;
      width: 2.375rem;
      height: 2.375rem;
      -webkit-transform: rotate(52deg);
      -ms-transform: rotate(52deg);
      transform: rotate(52deg);
      right: calc(50% - 470px - 1.875rem);
      border-left: 1px solid #eeeeee;
      right: calc(50% - 570px - 1.875rem); } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .header-top .container {
      max-width: 100%; } }
  .header-top ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    justify-content: flex-end; }
    @media (max-width: 767px) {
      .header-top ul {
        justify-content: space-between; } }
    .header-top ul li {
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 600; }
      .header-top ul li a {
        color: #404041;
        font-weight: 600; }
      @media (min-width: 768px) {
        .header-top ul li:last-child {
          margin-left: 12px; } }

.header-address {
  background-color: #F2F2F2;
  padding-top: 10px;
  padding-bottom: 12px;
  padding-top: 6px;
  padding-bottom: 8px;
  margin-left: auto;
  position: relative;
  z-index: 5; }
  @media (max-width: 350px) {
    .header-address {
      font-size: 12px; } }
  @media (min-width: 1200px) {
    .header-address:before {
      position: absolute;
      content: '';
      background-color: #ffffff;
      border-top: 1px solid #eeeeee;
      bottom: 0;
      left: .9375rem;
      left: 0;
      height: 1.5625rem;
      width: 16.875rem; } }

.navbar[class*="navbar-expand-"] {
  background-color: #FFFFFF;
  padding: 0;
  position: relative;
  z-index: 10;
  transition: all 1s ease-in-out; }
  @media (max-width: 991px) {
    .navbar[class*="navbar-expand-"] {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px;
      padding-bottom: 10px; } }
  @media (min-width: 1200px) {
    .navbar[class*="navbar-expand-"]:before, .navbar[class*="navbar-expand-"]:after {
      position: absolute;
      content: '';
      background-color: #ffffff;
      width: 2.375rem;
      height: 2.375rem;
      top: -1.0625rem; } }
  @media (min-width: 1200px) {
    .navbar[class*="navbar-expand-"]:before {
      -webkit-transform: rotate(52deg);
      -ms-transform: rotate(52deg);
      transform: rotate(52deg);
      border-left: 1px solid #eeeeee;
      left: calc(50% - 470px - .4375rem);
      left: calc(50% - 570px - .4375rem); } }
  @media (min-width: 1200px) {
    .navbar[class*="navbar-expand-"]:after {
      -webkit-transform: rotate(-52deg);
      -ms-transform: rotate(-52deg);
      transform: rotate(-52deg);
      border-right: 1px solid #eeeeee;
      left: calc(50% - 470px + 16.875rem);
      left: calc(50% - 570px + 16.875rem); } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .navbar[class*="navbar-expand-"] .container {
      max-width: 100%; } }
  .navbar[class*="navbar-expand-"] .navbar-toggler {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer; }
    .navbar[class*="navbar-expand-"] .navbar-toggler:focus {
      outline: none; }
    .navbar[class*="navbar-expand-"] .navbar-toggler .navbar-toggle-icon {
      background: #000000;
      display: block;
      height: 3px;
      width: 100%;
      border-radius: 0;
      opacity: 1;
      left: 0;
      margin-bottom: 6px;
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -ms-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out; }
      .navbar[class*="navbar-expand-"] .navbar-toggler .navbar-toggle-icon:nth-child(1) {
        top: 0px; }
      .navbar[class*="navbar-expand-"] .navbar-toggler .navbar-toggle-icon:nth-child(2), .navbar[class*="navbar-expand-"] .navbar-toggler .navbar-toggle-icon:nth-child(3) {
        top: 10px;
        top: 8px; }
    .navbar[class*="navbar-expand-"] .navbar-toggler.on .navbar-toggle-icon:nth-child(1) {
      top: 11px;
      width: 0%;
      left: 50%; }
    .navbar[class*="navbar-expand-"] .navbar-toggler.on .navbar-toggle-icon:nth-child(2) {
      transform: rotate(45deg); }
    .navbar[class*="navbar-expand-"] .navbar-toggler.on .navbar-toggle-icon:nth-child(3) {
      transform: rotate(-45deg);
      margin-top: -8px; }
  .navbar[class*="navbar-expand-"] .navbar-brand {
    padding-top: 0;
    padding-bottom: 0; }
    @media (max-width: 1199px) {
      .navbar[class*="navbar-expand-"] .navbar-brand {
        max-width: 150px; } }
    @media (min-width: 1200px) {
      .navbar[class*="navbar-expand-"] .navbar-brand {
        padding-left: 20px;
        margin-top: -7px; } }
  @media (min-width: 992px) {
    .navbar[class*="navbar-expand-"] .navbar-nav {
      align-items: center; } }
  .navbar[class*="navbar-expand-"] .navbar-nav li {
    transition: all 1s ease; }
    @media (max-width: 991px) {
      .navbar[class*="navbar-expand-"] .navbar-nav li {
        margin-bottom: 3px; } }
    @media (min-width: 992px) {
      .navbar[class*="navbar-expand-"] .navbar-nav li:not(:last-child) {
        margin-right: 20px; } }
    @media (min-width: 1200px) {
      .navbar[class*="navbar-expand-"] .navbar-nav li:not(:last-child) {
        margin-right: 30px; } }
    .navbar[class*="navbar-expand-"] .navbar-nav li a {
      font-weight: 600;
      color: #999999;
      display: block; }
      @media (min-width: 1200px) {
        .navbar[class*="navbar-expand-"] .navbar-nav li a {
          letter-spacing: 0.02em; } }
      @media (max-width: 992px) and (max-width: 1199px) {
        .navbar[class*="navbar-expand-"] .navbar-nav li a {
          font-size: 14px; } }
      .navbar[class*="navbar-expand-"] .navbar-nav li a:focus {
        outline: none; }
      .navbar[class*="navbar-expand-"] .navbar-nav li a:hover {
        color: #000000; }
    .navbar[class*="navbar-expand-"] .navbar-nav li.icon-home {
      position: relative; }
      @media (min-width: 992px) {
        .navbar[class*="navbar-expand-"] .navbar-nav li.icon-home {
          padding: 7px 30px 14px 0; } }
      @media (min-width: 992px) {
        .navbar[class*="navbar-expand-"] .navbar-nav li.icon-home:after {
          content: '';
          background-color: rgba(0, 0, 0, 0.1);
          height: 70%;
          width: 1px;
          position: absolute;
          right: 0;
          top: 15px; } }
      .navbar[class*="navbar-expand-"] .navbar-nav li.icon-home a {
        font-size: 25px; }
        @media (min-width: 1200px) {
          .navbar[class*="navbar-expand-"] .navbar-nav li.icon-home a {
            padding: 8px 5px !important; } }
    .navbar[class*="navbar-expand-"] .navbar-nav li.btn-yellow {
      padding: 0;
      border: none; }
      .navbar[class*="navbar-expand-"] .navbar-nav li.btn-yellow a {
        background-color: #f39637;
        color: #FFFFFF;
        padding: 8px 22px;
        border: 1px solid transparent;
        display: inline-block;
        font-size: 14px; }
        @media (min-width: 992px) and (max-width: 1199px) {
          .navbar[class*="navbar-expand-"] .navbar-nav li.btn-yellow a {
            padding: 10px 20px; } }
        .navbar[class*="navbar-expand-"] .navbar-nav li.btn-yellow a:hover {
          background-color: #FFFFFF;
          color: #f39637;
          border: 1px solid #f39637; }
      @media (min-width: 1200px) {
        .navbar[class*="navbar-expand-"] .navbar-nav li.btn-yellow {
          margin-left: 30px; } }
    .navbar[class*="navbar-expand-"] .navbar-nav li:not(.btn-yellow) a {
      margin-top: 5px;
      padding: 8px 5px; }
      @media (min-width: 1200px) {
        .navbar[class*="navbar-expand-"] .navbar-nav li:not(.btn-yellow) a {
          padding: 4px ​5px 12px 5p; } }
    .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-toggle:after {
      content: '\f0d7';
      border: none;
      font-family: 'FontAwesome';
      vertical-align: middle; }
      @media (min-width: 1200px) {
        .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-toggle:after {
          display: block;
          text-align: center;
          position: absolute;
          left: 0;
          right: 0; } }
      @media (max-width: 991px) {
        .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-toggle:after {
          display: none; } }
    @media (max-width: 991px) {
      .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-toggle .glyphicon {
        display: none; } }
    .navbar[class*="navbar-expand-"] .navbar-nav li .sub-menu,
    .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-menu {
      position: absolute;
      padding: 0;
      border-radius: 0;
      min-width: 240px; }
      .navbar[class*="navbar-expand-"] .navbar-nav li .sub-menu li,
      .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-menu li {
        margin-right: 0; }
        .navbar[class*="navbar-expand-"] .navbar-nav li .sub-menu li a,
        .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-menu li a {
          padding: 6px 10px;
          font-size: 14px;
          display: block;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children {
      position: relative; }
      @media (min-width: 992px) {
        .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown:hover:after, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children:hover:after {
          color: #FF0000; } }
      .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown .drop-toggler, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children .drop-toggler {
        color: #000000;
        font-size: 11px; }
        @media (min-width: 992px) {
          .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown .drop-toggler, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children .drop-toggler {
            display: none; } }
        @media (max-width: 991px) {
          .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown .drop-toggler, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children .drop-toggler {
            position: absolute;
            right: 0;
            width: 32px;
            right: 0;
            height: 32px;
            line-height: 32px;
            top: 0;
            text-align: center; } }
      .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown:hover .sub-menu, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children:hover .sub-menu {
        display: block; }
  @media (max-width: 991px) {
    .navbar[class*="navbar-expand-"].open {
      background-color: #FFFFFF; } }

body.home-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-home > a,
body.contact-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-contact > a,
body.project-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-project > a,
body.about-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-about > a,
body.news-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-news > a,
body.service-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-service > a {
  color: #000000;
  border-bottom: 3px solid #f39637; }

body.project-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-project > a:after,
body.service-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-service > a:after {
  display: none; }

.section .title h2 {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase; }

.section-welcome {
  background-color: #FFFFFF;
  padding-top: 30px;
  padding-bottom: 50px;
  font-size: 15px;
  line-height: 24px; }
  .section-welcome .title {
    margin-bottom: 12px; }
  .section-welcome h2 {
    color: #f39637;
    padding-left: 40px;
    padding-right: 40px;
    position: relative; }
    .section-welcome h2:before, .section-welcome h2:after {
      content: '';
      position: absolute;
      height: 32px;
      width: 32px;
      left: 0;
      background-size: cover;
      background-repeat: no-repeat;
      top: 10px; }
    .section-welcome h2:before {
      background-image: url("../images/titleborder-lt.png"); }
    .section-welcome h2:after {
      background-image: url("../images/titleborder-rt.png");
      left: auto;
      right: 0; }
    .section-welcome h2 span {
      color: #404041; }

.section-building {
  background-color: #F2F2F2;
  padding-top: 30px;
  padding-bottom: 30px; }
  .section-building .title {
    margin-bottom: 12px; }
    .section-building .title h2 {
      color: #404041; }
      .section-building .title h2 span {
        color: #f39637; }
  .section-building .building__wrap {
    background-color: #FFFFFF;
    margin-bottom: 20px; }
  .section-building .building__img img {
    width: 100%; }
  .section-building .building__content {
    background-color: #FFFFFF; }
    .section-building .building__content-wrap {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
      justify-content: space-between; }
      .section-building .building__content-wrap > div {
        padding-left: 10px;
        padding-right: 10px; }
    .section-building .building__content-title {
      margin-top: 0;
      padding-top: 12px;
      padding-bottom: 2px; }
      .section-building .building__content-title h3 {
        font-size: 15px;
        font-weight: 700;
        color: #404041;
        padding-left: 22px;
        text-transform: uppercase; }
    .section-building .building__content-explore {
      padding-top: 18px; }
      .section-building .building__content-explore a {
        padding-right: 22px;
        font-size: 15px;
        font-weight: 700;
        color: #f39637; }

.section-video .container-fluid {
  padding-left: 0;
  padding-right: 0; }

.section-video .video__wrap {
  position: relative;
  height: 100%;
  width: 100%; }
  .section-video .video__wrap .overlay {
    background: rgba(0, 0, 0, 0.3);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), color-stop(48%, rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3)));
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 48%, rgba(0, 0, 0, 0.3) 100%);
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; }

.section-video .video__youTube {
  padding: 56.25% 0 0 0;
  position: relative; }
  .section-video .video__youTube iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.section-video .video__content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 570px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  color: #000000; }
  @media (min-width: 1200px) {
    .section-video .video__content {
      padding-left: 70px; } }
  @media (max-width: 1199px) {
    .section-video .video__content {
      margin-top: 40px;
      height: auto;
      max-width: 100%; } }
  .section-video .video__content h2 {
    color: #404041;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 25px;
    margin-bottom: 22px; }
    .section-video .video__content h2 span {
      color: #f39637; }

.section-testimonial {
  background-color: #f3f3f3;
  color: #1c1c1c;
  font-weight: 600;
  padding: 40px 0 30px;
  margin-top: 45px; }
  .section-testimonial .testimonial__wrapper {
    max-width: 945px;
    font-weight: lighter;
    font-style: italic;
    color: #1c1c1c;
    line-height: 24px; }
    .section-testimonial .testimonial__wrapper .slick-dots {
      bottom: -36px; }
      .section-testimonial .testimonial__wrapper .slick-dots li {
        height: auto;
        width: auto; }
        .section-testimonial .testimonial__wrapper .slick-dots li button {
          background-color: #f39637;
          height: 12px;
          width: 12px;
          border-radius: 50%; }
          .section-testimonial .testimonial__wrapper .slick-dots li button:before {
            content: '';
            height: 0;
            width: 0; }
    .section-testimonial .testimonial__wrapper h3 {
      color: #f39637;
      font-size: 15px;
      font-weight: 700;
      font-style: normal;
      text-transform: uppercase;
      padding-left: 70px;
      position: relative;
      margin-bottom: 18px; }
      .section-testimonial .testimonial__wrapper h3:before {
        background-image: url("../images/quote-left.png");
        background-repeat: no-repeat;
        content: '\f10d';
        content: '';
        font-family: 'FontAwesome';
        color: #FFFFFF;
        position: absolute;
        left: 0;
        font-size: 30px;
        width: 31px;
        height: 23px;
        background-size: cover;
        top: 0; }
    .section-testimonial .testimonial__wrapper .client-name {
      color: #f39637;
      font-size: 15px;
      font-weight: 600;
      position: relative;
      padding-left: 70px;
      margin-top: 25px; }
      .section-testimonial .testimonial__wrapper .client-name:before {
        content: '';
        background-color: #f39637;
        width: 60px;
        height: 2px;
        position: absolute;
        left: 0;
        top: 10px; }
  .section-testimonial .testimonial__item {
    padding-left: 70px; }

.section-download {
  background-color: #404041;
  color: #FFFFFF;
  margin-top: 45px; }
  .section-download .download__wrapper {
    max-width: 1140px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px; }
  .section-download .download__wrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px; }
  .section-download .download__left, .section-download .download__btnWrap {
    padding-left: 15px;
    padding-right: 15px; }
  @media (min-width: 768px) {
    .section-download .download__left {
      max-width: 60%;
      flex: 0 0 60%; } }
  @media (min-width: 768px) {
    .section-download .download__btnWrap {
      max-width: 40%;
      flex: 0 0 40%; } }
  .section-download .download__left {
    position: relative;
    overflow: hidden; }
    @media (min-width: 768px) {
      .section-download .download__left:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 100px solid #f3f3f3;
        border-bottom: 100px solid transparent;
        border-left: 100px solid transparent;
        position: absolute;
        top: 0;
        right: 0; } }
    .section-download .download__left .content {
      padding-top: 31px;
      font-size: 18px;
      font-weight: 700; }
      @media (max-width: 767px) {
        .section-download .download__left .content {
          padding-bottom: 24px; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .section-download .download__left .content {
          font-size: 17px;
          padding-right: 50px; } }
  .section-download .download__btnWrap {
    position: relative;
    background-color: #f3f3f3; }
    @media (max-width: 767px) {
      .section-download .download__btnWrap {
        width: 100%;
        text-align: center; } }
    @media (min-width: 768px) {
      .section-download .download__btnWrap {
        text-align: right; } }
    @media (min-width: 768px) {
      .section-download .download__btnWrap:after {
        content: '';
        background: #f3f3f3;
        position: absolute;
        width: 1000%;
        height: 100%;
        bottom: 0; } }
    .section-download .download__btnWrap .button-wrap {
      padding-top: 26px;
      padding-bottom: 23px; }
    .section-download .download__btnWrap .btn-download {
      background-color: #f39637;
      color: #FFFFFF;
      border-radius: 0;
      letter-spacing: 1px;
      font-weight: 600;
      min-width: 175px;
      text-align: center;
      display: inline-block; }
      .section-download .download__btnWrap .btn-download i {
        margin-right: 10px;
        font-size: 13px; }

.section-contact {
  padding-top: 60px;
  padding-bottom: 60px; }
  .section-contact .contact__info h2 {
    font-size: 20px;
    padding-bottom: 6px;
    margin-bottom: 20px;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.5); }
  .section-contact .contact__info ul li {
    margin-bottom: 15px; }
    .section-contact .contact__info ul li strong {
      display: block;
      margin-bottom: 5px; }
    .section-contact .contact__info ul li a {
      color: #000000; }
  .section-contact .contact__map {
    margin-top: 30px; }
    .section-contact .contact__map h2 {
      font-size: 20px;
      padding-bottom: 6px;
      margin-bottom: 20px;
      border-bottom: 2px dotted rgba(0, 0, 0, 0.5); }
    .section-contact .contact__map iframe {
      height: 520px;
      width: 100%; }
  @media (max-width: 767px) {
    .section-contact .contact__form {
      margin-top: 30px; } }
  .section-contact .contact__form h2 {
    font-size: 20px;
    padding-bottom: 6px;
    margin-bottom: 20px;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.5); }
  .section-contact .contact__form .form-control {
    border-radius: 0; }
    .section-contact .contact__form .form-control:focus {
      box-shadow: none; }
  .section-contact .contact__form .code-sec {
    margin-top: 20px;
    max-width: 200px; }
  .section-contact .contact__form .btn-default {
    border-radius: 0;
    background-color: #f39637;
    color: #FFFFFF;
    margin-top: 20px;
    min-width: 200px;
    text-transform: uppercase; }

.section-projects {
  padding-top: 60px; }
  .section-projects .project__wrap {
    margin-bottom: 30px; }
    .section-projects .project__wrap figure {
      margin-bottom: 0;
      height: 350px;
      background-size: cover;
      background-position: center; }
      .section-projects .project__wrap figure a {
        display: block;
        height: 100%;
        width: 100%; }
  .section-projects .project__title {
    padding: 10px 10px 0 10px;
    border: 1px solid #ddd;
    text-align: center; }
    .section-projects .project__title h3 {
      font-size: 18px;
      font-weight: 600; }
      .section-projects .project__title h3 a {
        color: currentColor; }
        .section-projects .project__title h3 a:hover {
          color: #f39637; }

.section-about {
  padding-top: 40px;
  padding-bottom: 50px; }
  .section-about .about__image {
    margin-bottom: 30px; }
  .section-about .about__content {
    margin-bottom: 30px; }

.section-news {
  padding-top: 60px;
  padding-bottom: 30px; }
  .section-news .news__wrap {
    margin-bottom: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
    @media (min-width: 768px) {
      .section-news .news__wrap {
        margin-bottom: 30px; } }
  .section-news .news__image {
    position: relative;
    overflow: hidden; }
    .section-news .news__image img {
      width: 100%;
      transition: all 0.3s ease-in-out; }
    .section-news .news__image:hover img {
      transform: scale(1.15); }
  .section-news .news__info {
    padding: 20px; }
    .section-news .news__info h3 {
      font-size: 20px; }

.section-workFlow {
  background-color: #f8f8f8;
  padding: 50px 0; }
  @media (max-width: 991px) {
    .section-workFlow .workFlow__left {
      margin-bottom: 30px; } }
  .section-workFlow .workFlow__left h2 {
    color: #f39637;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    padding-right: 40px;
    position: relative;
    margin-bottom: 40px; }
    .section-workFlow .workFlow__left h2:after {
      content: '';
      background-image: url(../images/titleborder-rt.png);
      left: auto;
      right: 0;
      position: absolute;
      height: 32px;
      width: 32px;
      background-size: cover;
      background-repeat: no-repeat;
      top: 10px; }
  .section-workFlow .workFlow__left ul {
    font-size: 20px; }
    .section-workFlow .workFlow__left ul li {
      margin-bottom: 10px; }
      .section-workFlow .workFlow__left ul li a {
        color: currentColor;
        position: relative;
        padding-bottom: 3px; }
        .section-workFlow .workFlow__left ul li a:before {
          content: '';
          background-color: #f39637;
          position: absolute;
          left: 50%;
          right: 50%;
          bottom: 0;
          height: 1px;
          width: 0;
          transition: all 0.2s ease-in-out; }
        .section-workFlow .workFlow__left ul li a:hover:before {
          width: 100%;
          left: 0; }
  .section-workFlow .workFlow__right h2 {
    color: #f39637;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 700; }
  .section-workFlow .workFlow__right ul li {
    position: relative;
    padding-left: 24px;
    margin-bottom: 8px; }
    .section-workFlow .workFlow__right ul li:before {
      content: '';
      background-color: #FFFFFF;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.9);
      left: 0;
      display: inline-block;
      position: absolute;
      top: 8px; }

.section-aboutLink {
  background-color: #FFFFFF;
  padding: 40px 0; }
  .section-aboutLink .aboutLink__wrap ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px; }
    .section-aboutLink .aboutLink__wrap ul li {
      padding-left: 10px;
      padding-right: 10px; }
      .section-aboutLink .aboutLink__wrap ul li a {
        text-transform: uppercase;
        color: #000000;
        font-weight: 700;
        font-size: 15px;
        padding-bottom: 6px;
        border-bottom: 3px solid #000000;
        display: inline-block; }
      .section-aboutLink .aboutLink__wrap ul li:first-child a {
        border-bottom: 3px solid #f39637; }

.section-service {
  background-color: #FFFFFF;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 1200px) {
    .section-service .container-fluid {
      padding-left: 0;
      padding-right: 0; } }
  @media (max-width: 1199px) {
    .section-service .row:not(:last-child) {
      margin-bottom: 30px; } }
  .section-service .service__image {
    background-size: cover;
    background-position: center;
    height: 100%; }
    @media (max-width: 991px) {
      .section-service .service__image {
        background-size: 0; } }
    .section-service .service__image img {
      width: 100%; }
      @media (min-width: 1200px) {
        .section-service .service__image img {
          display: none; } }
  .section-service .service__content {
    background-color: #FFFFFF;
    padding: 30px 50px; }
    .section-service .service__content h2 {
      color: #2e3092;
      font-weight: 700; }
    .section-service .service__content ul li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 8px; }
      .section-service .service__content ul li:before {
        content: '\f00c';
        font-family: 'FontAwesome';
        position: absolute;
        left: 0; }

.inner-hero {
  background-size: cover;
  background-position: center; }
  .inner-hero-wrapper {
    min-height: 180px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #FFFFFF; }
    .inner-hero-wrapper h1 {
      color: #FFFFFF;
      font-weight: 700;
      text-transform: uppercase; }

.footer-top {
  background: #404041;
  color: #FFFFFF;
  padding: 48px 0 50px;
  font-size: 13px;
  line-height: 28px; }
  @media (max-width: 1199px) {
    .footer-top .footer__wrap {
      margin-bottom: 30px; } }
  .footer-top .footer__wrap h3 {
    color: #f39637;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 18px; }
  .footer-top .footer__wrap a {
    color: #FFFFFF; }
    .footer-top .footer__wrap a:hover {
      color: #f39637; }
  .footer-top .footer__navTop {
    column-count: 2;
    column-gap: 2; }
  .footer-top .contactUs p span {
    display: inline-block;
    margin-left: 36px; }

.footer-copyright {
  background-color: #222222;
  font-size: 13px;
  color: #fff;
  padding: 27px 0; }
  .footer-copyright a {
    color: #f39637; }
    .footer-copyright a:hover {
      text-decoration: underline; }

.section-hero .hero__slider .overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2; }

.section-hero .hero-item {
  position: relative; }
  @media (max-width: 767px) {
    .section-hero .hero-item img.img-100 {
      height: 400px;
      object-fit: cover; } }

.section-hero .hero-wrapper {
  display: flex;
  align-items: flex-end;
  padding-top: 50px;
  padding-bottom: 30px;
  color: #FFFFFF;
  position: absolute;
  bottom: 0;
  top: 0;
  height: 100%;
  align-items: center;
  z-index: 5;
  font-size: 25px;
  font-weight: 700; }
  @media (max-width: 767px) {
    .section-hero .hero-wrapper {
      font-size: 20px; } }
  .section-hero .hero-wrapper h2 {
    color: #f39637;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 22px; }
    @media (max-width: 767px) {
      .section-hero .hero-wrapper h2 {
        font-size: 30px; } }
  @media (max-width: 767px) {
    .section-hero .hero-wrapper p br {
      display: none; } }
  .section-hero .hero-wrapper .btn {
    background-color: transparent;
    border: 1px solid #FFFFFF;
    color: #f39637;
    border-radius: 0;
    text-transform: uppercase;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 600;
    margin-top: 10px;
    font-size: 20px; }
    .section-hero .hero-wrapper .btn:hover {
      background-color: #f39637;
      border: 1px solid #f39637;
      color: #FFFFFF; }
    .section-hero .hero-wrapper .btn i {
      margin-left: 15px; }
