// custom styles
.section {
  .title {

    h2 {
      display: inline-block;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }
  }

  &-welcome {
    background-color: color(typography, 1);
    padding-top: 30px;
    padding-bottom: 50px;
    font-size: 15px;
    line-height: 24px;

    .title {
      margin-bottom: 12px;
    }

    h2 {

      color: color(typography, 5);

      padding-left: 40px;
      padding-right: 40px;
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
        height: 32px;
        width: 32px;
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
        top: 10px;
      }

      &:before {
        background-image: url('../images/titleborder-lt.png');
      }

      &:after {
        background-image: url('../images/titleborder-rt.png');
        left: auto;
        right: 0;
      }

      span {
        color: color(typography, 6);
      }
    }


  }

  &-building {
    background-color: color(typography, 4);
    padding-top: 30px;
    padding-bottom: 30px;

    .title {
      margin-bottom: 12px;

      h2 {
        color: color(typography, 6);

        span {
          color: color(typography, 5);
        }

      }
    }

    .building {
      &__wrap {
        background-color: color(typography, 1);
        margin-bottom: 20px;
      }
      &__img {
        img {
          width: 100%;
        }
      }

      &__content {
        background-color: color(typography, 1);

        &-wrap {
          display: flex;
          flex-wrap: wrap;
          margin-left: -10px;
          margin-right: -10px;
          justify-content: space-between;

          >div {
            padding-left: 10px;
            padding-right: 10px;
          }
        }

        &-title {
          margin-top: 0;
          padding-top: 12px;
          padding-bottom: 2px;

          h3 {
            font-size: 15px;
            font-weight: 700;
            color: color(typography, 6);
            padding-left: 22px;
            text-transform: uppercase;
          }
        }

        &-explore {
          padding-top: 18px;

          a {
            padding-right: 22px;
            font-size: 15px;
            font-weight: 700;
            color: color(typography, 5);
          }
        }
      }
    }
  }

  &-video {
    .container-fluid {
      padding-left: 0;
      padding-right: 0;
    }

    .video {
      &__wrap {
        position: relative;
        height: 100%;
        width: 100%;

        .overlay {
          background: rgba(0, 0, 0, 0.3);
          background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), color-stop(48%, rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3)));
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 48%, rgba(0, 0, 0, 0.3) 100%);
          position: absolute;
          z-index: 1;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
        }
      }


      &__youTube {
        // padding: 49.360% 0 0 0;
        padding: 56.25% 0 0 0;
        position: relative;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        height: 100%;
        max-width: 570px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 20px;
        font-weight: 300;
        line-height: 28px;
        color: color(typography, 3);

        @media(min-width: 1200px) {
          padding-left: 70px;
        }
        @media(max-width: 1199px) {
          margin-top: 40px;
          height: auto;
          max-width: 100%;
        }

        h2 {
          color: color(typography, 6);
          font-size: 18px;
          font-weight: 700;
          text-transform: uppercase;
          margin-top: 25px;
          margin-bottom: 22px;

          span {
            color: color(typography, 5);
          }
        }


      }
    }
  }
  &-testimonial {
    background-color: #f3f3f3;
    color: #1c1c1c;
    font-weight: 600;
    padding: 40px 0 30px;
    margin-top: 45px;
    .testimonial {
      &__wrapper {
        max-width: 945px;
        font-weight: lighter;
        font-style: italic;
        color: #1c1c1c;
        line-height: 24px;
        .slick {
          &-dots {
            bottom: -36px;
            li {
              height: auto;
              width: auto;
              button {
                background-color: color(typography, 5);
                height: 12px;
                width: 12px;
                border-radius: 50%;
                &:before {
                  content: '';
                  height: 0;
                  width: 0;
                }
              }
            }
          }
        }
        h3 {
          color: color(typography, 5);
          font-size: 15px;
          font-weight: 700;
          font-style: normal;
          text-transform: uppercase;
          padding-left: 70px;
          position: relative;
          margin-bottom: 18px;
          &:before {
            background-image: url('../images/quote-left.png');
            background-repeat: no-repeat;
            content: '\f10d';
            content: '';
            font-family:'FontAwesome';
            color: color(typography, 1);
            position: absolute;
            left: 0;
            font-size: 30px;
            width: 31px;
            height: 23px;
            background-size: cover;
            top: 0;
          }
        }
        .client-name {
          color: color(typography, 5);
          font-size: 15px;
          font-weight: 600;
          position: relative;
          padding-left: 70px;
          margin-top: 25px;
          &:before {
            content: '';
            background-color: color(typography, 5);
            width: 60px;
            height: 2px;
            position: absolute;
            left: 0;
            top: 10px;
          }
        }
      }
      &__item {
        padding-left: 70px;
      }
    }
  }


  &-download {
    background-color: color(typography, 6);
    color: color(typography, 1);
    margin-top: 45px;

    .download {
      &__wrapper {
        max-width: 1140px;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
      }

      &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
      }

      &__left,
      &__btnWrap {
        padding-left: 15px;
        padding-right: 15px;



      }

      &__left {
        @media(min-width: 768px) {
          max-width: 60%;
          flex: 0 0 60%;
        }
      }

      &__btnWrap {
        @media(min-width: 768px) {
          max-width: 40%;
          flex: 0 0 40%;
        }
      }

      &__left {
        position: relative;
        overflow: hidden;

        &:after {
          @media(min-width: 768px) {
            content: '';
            width: 0;
            height: 0;
            border-top: 100px solid #f3f3f3;
            border-bottom: 100px solid transparent;
            border-left: 100px solid transparent;
            position: absolute;
            top: 0;
            right: 0;
          }

        }

        .content {
          padding-top: 31px;
          // padding-bottom: 24px;
          font-size: 18px;
          font-weight: 700;
          @media(max-width: 767px) {
            padding-bottom: 24px;
          }

          @media(min-width: 768px) and (max-width: 991px) {
            font-size: 17px;
            padding-right: 50px;
          }
        }
      }

      &__btnWrap {
        position: relative;

        @media(max-width: 767px) {
          width: 100%;
          text-align: center;
        }

        @media(min-width: 768px) {
          text-align: right;
        }

        &:after {
          @media(min-width: 768px) {
            content: '';
            background: #f3f3f3;
            position: absolute;
            width: 1000%;
            height: 100%;
            bottom: 0;
          }
        }

        .button-wrap {
          padding-top: 26px;
          padding-bottom: 23px;
        }

        background-color: #f3f3f3;


        .btn {
          &-download {
            background-color: color(typography, 5);
            color: color(typography, 1);
            border-radius: 0;
            letter-spacing: 1px;
            font-weight: 600;
            min-width: 175px;
            text-align: center;
            display: inline-block;
            i {
              margin-right: 10px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  &-contact {
    padding-top: 60px;
    padding-bottom: 60px;

    .contact {
      &__info {
        h2 {
          font-size: 20px;
          padding-bottom: 6px;
          margin-bottom: 20px;
          border-bottom: 2px dotted rgba(color(typography, 3), .5);
        }

        ul {
          li {
            margin-bottom: 15px;

            strong {
              display: block;
              margin-bottom: 5px;
            }

            a {
              color: color(typography, 3);
            }
          }
        }
      }

      &__map {
        margin-top: 30px;

        h2 {
          font-size: 20px;
          padding-bottom: 6px;
          margin-bottom: 20px;
          border-bottom: 2px dotted rgba(color(typography, 3), .5);
        }

        iframe {
          height: 520px;
          width: 100%;
        }
      }

      &__form {
        @media(max-width: 767px) {
          margin-top: 30px;
        }

        h2 {
          font-size: 20px;
          padding-bottom: 6px;
          margin-bottom: 20px;
          border-bottom: 2px dotted rgba(color(typography, 3), .5);
        }

        .form-control {
          border-radius: 0;

          &:focus {
            box-shadow: none;
          }
        }

        .code-sec {
          margin-top: 20px;
          max-width: 200px;
        }

        .btn-default {
          border-radius: 0;
          background-color: color(typography, 5);
          color: color(typography, 1);
          margin-top: 20px;
          min-width: 200px;
          text-transform: uppercase;
        }
      }
    }
  }

  &-projects {
    padding-top: 60px;
    .project {
      &__wrap {
        margin-bottom: 30px;
        figure {
          margin-bottom: 0;
          height: 350px;
          background-size: cover;
          background-position: center;
          a {
            display: block;
            height: 100%;
            width: 100%;
          }
        }
      }
      &__title {
        padding: 10px 10px 0 10px;
        border: 1px solid #ddd;
        text-align: center;
        h3 {
          font-size: 18px;
          font-weight: 600;
          a {
            color: currentColor;
            &:hover {
              color: color(typography, 5);
            }
          }
        }
      }
    }
  }
  &-about {
    padding-top: 40px;
    padding-bottom: 50px;
    .about {
      &__image {
        margin-bottom: 30px;
      }
      &__content {
        margin-bottom: 30px;
      }
    }
  }
  &-news {
    padding-top: 60px;
    padding-bottom: 30px;
    .news {
      &__wrap {
        margin-bottom: 15px;
        box-shadow: 0 0 10px rgba(color(typography, 3), .2);
        @media(min-width: 768px) {
          margin-bottom: 30px;
        }
      }
      &__image {
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          transition: all 0.3s ease-in-out;
        }
        &:hover {
          img {
            transform: scale(1.15);
          }
        }
      }
      &__info {
        h3 {
          font-size: 20px;
        }
        padding: 20px;
      }
    }
  }
  &-workFlow {
    background-color: #f8f8f8;
    padding: 50px 0;
    .workFlow {
      &__left {
        @media(max-width: 991px) {
          margin-bottom: 30px;
        }
        h2 {
          color: #f39637;
          display: inline-block;
          font-size: 20px;
          font-weight: 700;
          text-transform: uppercase;
          padding-right: 40px;
          position: relative;
          margin-bottom: 40px;
          &:after {
            content: '';
            background-image: url(../images/titleborder-rt.png);
            left: auto;
            right: 0;
            position: absolute;
            height: 32px;
            width: 32px;
            background-size: cover;
            background-repeat: no-repeat;
            top: 10px;
          }
        }
        ul {
          font-size: 20px;
          li {
            margin-bottom: 10px;
            a {
              color: currentColor;
              position: relative;
              padding-bottom: 3px;
              &:before {
                content: '';
                background-color: #f39637;
                position: absolute;
                left: 50%;
                right: 50%;
                bottom: 0;
                height: 1px;
                width: 0;
                transition: all 0.2s ease-in-out;
              }
              &:hover {
                &:before {
                  width: 100%;
                  left: 0;
                }
              }
            }
          }
        }
      }
      &__right {
        h2 {
          color: #f39637;
          font-size: 20px;
          margin-bottom: 20px;
          font-weight: 700;
        }
        ul {
          li {
            position: relative;
            padding-left: 24px;
            margin-bottom: 8px;
            &:before {
              content: '';
              background-color: color(typography, 1);
              height: 6px;
              width: 6px;
              border-radius: 50%;
              border: 1px solid rgba(color(typography, 3),.9);
              left: 0;
            display: inline-block;
            position: absolute;
            top: 8px;
            }
          }
        }
      }
    }
  }
  &-aboutLink {
    background-color: color(typography, 1);
    padding: 40px 0;
    .aboutLink {
      &__wrap {
        ul {
          display: flex;
          flex-wrap: wrap;
          margin-left: -10px;
          margin-right: -10px;
          li {
            padding-left: 10px;
            padding-right: 10px;
            a {
              text-transform: uppercase;
              color: color(typography, 3);
              font-weight: 700;
              font-size: 15px;
              padding-bottom: 6px;
              border-bottom: 3px solid color(typography, 3);
              display: inline-block;
            }
            &:first-child {
              a {
                border-bottom: 3px solid color(typography, 5);
              }
            }
          }
        }
      }
    }
  }
  &-service {
    background-color: color(typography, 1);
    padding-top: 60px;
    padding-bottom: 60px;
    .container {
      &-fluid {
        @media(min-width: 1200px) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    .row {
      &:not(:last-child) {
        @media(max-width: 1199px) {
          margin-bottom: 30px;
        }
      }
    }
    .service { 
      &__image {
        background-size: cover;
        background-position: center;
        height: 100%;
        @media(max-width: 991px) {
          background-size: 0;
        }
        img {
          width: 100%;
          @media(min-width: 1200px) {
            display: none;
          }
        }
      }
      &__content {
        background-color: color(typography, 1);
        padding: 30px 50px;
        h2 {
          color: #2e3092;
          font-weight: 700;
        }
        ul {
          
          li {
            position: relative;
            padding-left: 30px;
            margin-bottom: 8px;
            &:before {
              content: '\f00c';
              font-family:'FontAwesome';
              position: absolute;
              left: 0;
            }
          }
        }
      }
    }
  }
}
.inner-hero {
  background-size: cover;
  background-position: center;
  &-wrapper {
    min-height: 180px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: color(typography,1);
    h1 {
      color: color(typography,1);
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}