.footer {
    &-top {
        background: color(typography, 6);
        color: color(typography, 1);
        padding: 48px 0 50px;
        font-size: 13px;
        line-height: 28px;
        .footer {
            &__wrap {
                @media(max-width:1199px) {
                    margin-bottom: 30px;
                }
                h3 {
                    color: color(typography, 5);
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 28px;
                    margin-bottom: 18px;
                }
                a {
                    color: color(typography, 1);
                    &:hover {
                        color: color(typography, 5);
                    }
                }
            }
            &__navTop {
                column-count: 2;
                column-gap: 2;
            }

        }
        .contactUs {
            p {
                span {
                    display: inline-block;
                    margin-left: 36px;
                }
            }
        }
    }
    &-copyright {
        background-color: color(typography, 8);
        font-size: 13px;
        color: #fff;
        padding: 27px 0;
        a {
            color: color(typography, 5);
            &:hover {
                text-decoration: underline;
            }
        }
    }
}