.header {
    &-site {
        position: relative;
        box-shadow: 0 0 15px rgba(0, 0, 0, .3);
    }

    &-top {
        background-color: color(typography, 4);
        color: color(typography, 6);
        font-size: 13px;
        line-height: 28px;
        position: relative;
        // overflow-x: hidden;

        &:before,
        &:after {
            @media(min-width: 1200px) {
                position: absolute;
                content: '';
                background-color: #ffffff
            }
        }

        &:before {
            @media(min-width: 1200px) {
                bottom: -1px;
                right: 0;
                height: 1.5625rem;
                width: calc(50% - 470px - .875rem);
                width: calc(50% - 570px - .875rem);
                border-top: 1px solid #eeeeee;
            }
        }

        &:after {
            @media(min-width: 1200px) {
                bottom: -1.375rem;
                width: 2.375rem;
                height: 2.375rem;
                -webkit-transform: rotate(52deg);
                -ms-transform: rotate(52deg);
                transform: rotate(52deg);
                right: calc(50% - 470px - 1.875rem);
                border-left: 1px solid #eeeeee;
                right: calc(50% - 570px - 1.875rem);
            }
        }

        .container {
            @media(min-width: 992px) and (max-width: 1199px) {
                max-width: 100%;
            }
        }

        &:after {
            // content: '';
            // background-color: color(typography, 5);
            // width: 100%;
            // right: 0;
            // height: 100%;
            // position: absolute;
            // z-index: 1;
            // top: 0;
            // left: 50%;
        }


        ul {
            display: flex;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;
            justify-content: flex-end;

            @media(max-width: 767px) {
                justify-content: space-between;
            }

            li {
                padding-left: 10px;
                padding-right: 10px;
                font-weight: 600;

                a {
                    color: color(typography, 6);
                    font-weight: 600;
                }

                &:last-child {

                    @media(min-width: 768px) {
                        margin-left: 12px;
                    }
                }

            }
        }
    }

    &-address {
        background-color: color(typography, 4);
        padding-top: 10px;
        padding-bottom: 12px;
        padding-top: 6px;
        padding-bottom: 8px;
        margin-left: auto;
        position: relative;
        z-index: 5;

        @media(max-width: 350px) {
            font-size: 12px;
        }

        &:before {
            @media(min-width: 1200px) {
                position: absolute;
                content: '';
                background-color: #ffffff;
                border-top: 1px solid #eeeeee;
                bottom: 0;
                left: .9375rem;
                left: 0;
                height: 1.5625rem;
                width: 16.875rem;
            }
        }


    }
}

.navbar {
    &[class*="navbar-expand-"] {
        background-color: color(bg, 1);
        padding: 0;
        
        position: relative;
        z-index: 10;
        transition: all 1s ease-in-out;

        @media(max-width: 991px) {
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        &:before,
        &:after {
            @media(min-width: 1200px) {
                position: absolute;
                content: '';
                background-color: #ffffff;
                width: 2.375rem;
                height: 2.375rem;
                top: -1.0625rem;
            }
        }

        &:before {
            @media(min-width: 1200px) {
                -webkit-transform: rotate(52deg);
                -ms-transform: rotate(52deg);
                transform: rotate(52deg);
                border-left: 1px solid #eeeeee;
                left: calc(50% - 470px - .4375rem);
                left: calc(50% - 570px - .4375rem);
            }
        }

        &:after {
            @media(min-width: 1200px) {
                -webkit-transform: rotate(-52deg);
                -ms-transform: rotate(-52deg);
                transform: rotate(-52deg);
                border-right: 1px solid #eeeeee;
                left: calc(50% - 470px + 16.875rem);
                left: calc(50% - 570px + 16.875rem);
            }
        }

        .container {
            @media(min-width: 992px) and (max-width: 1199px) {
                max-width: 100%;
            }
        }

        .navbar-toggler {
            width: 30px;
            height: 20px;
            position: relative;
            margin: 0;
            padding: 0;
            outline: none;
            border: none;
            @include transition(.5s ease-in-out);
            cursor: pointer;

            &:focus {
                outline: none;
            }

            .navbar-toggle-icon {
                background: color(typography, 3);
                display: block;
                // position: absolute;
                height: 3px;
                width: 100%;
                border-radius: 0;
                opacity: 1;
                left: 0;
                margin-bottom: 6px;
                transform: rotate(0deg);
                @include transition(.25s ease-in-out);

                &:nth-child(1) {
                    top: 0px;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    top: 10px;
                    top: 8px;
                }
            }

            &.on {
                .navbar-toggle-icon {
                    &:nth-child(1) {
                        top: 11px;
                        width: 0%;
                        left: 50%;
                    }

                    &:nth-child(2) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg);
                        margin-top: -8px;

                    }

                }
            }
        }

        .navbar {
            &-brand {
                padding-top: 0;
                padding-bottom: 0;

                @media(max-width: 1199px) {
                    max-width: 150px;
                }
                @media(min-width: 1200px) {
                    padding-left: 20px;
                    margin-top: -7px;
                }

            }

            &-nav {
                @media(min-width: 992px) {
                    align-items: center;
                }

                li {
                    transition: all 1s ease;

                    @media(max-width: 991px) {
                        margin-bottom: 3px;
                    }

                    &:not(:last-child) {
                        @media(min-width: 992px) {
                            margin-right: 20px;
                        }

                        @media(min-width: 1200px) {
                            margin-right: 30px;
                        }
                    }


                    a {
                        font-weight: 600;
                        color: color(typography, 7);
                        display: block;

                        @media(min-width: 1200px) {
                            letter-spacing: 0.02em;
                        }

                        @media(max-width: 992px) and (max-width: 1199px) {
                            font-size: 14px;
                        }

                        &:focus {
                            outline: none;
                        }

                        &:hover {
                            color: color(typography, 3);
                        }
                    }

                    &.icon-home {
                        position: relative;
                        @media(min-width: 992px) {
                            // border-right: 1px solid rgba(0, 0, 0, 0.1);
                            padding: 7px 30px 14px 0;
                        }
                        &:after {
                            @media(min-width: 992px) {
                                content: '';
                                background-color: rgba(0, 0, 0, 0.1);
                                height: 70%;
                                width: 1px;
                                position: absolute;
                                right: 0;
                                top: 15px;
                            }
                        }

                        a {
                            font-size: 25px;
                            @media(min-width: 1200px) {
                                padding: 8px 5px !important;
                            }
                        }
                    }

                    &.btn-yellow {
                        padding: 0;
                        border: none;
                        a {
                            background-color: color(typography, 5);
                            color: color(typography, 1);
                            padding: 8px 22px;
                            border: 1px solid transparent;
                            display: inline-block;
                            font-size: 14px;

                            @media(min-width: 992px) and (max-width: 1199px) {
                                padding: 10px 20px;
                            }

                            &:hover {
                                background-color: color(typography, 1);
                                color: color(typography, 5);
                                border: 1px solid color(typography, 5);
                            }
                        }
                        @media(min-width: 1200px) {
                            margin-left: 30px;
                        }
                    }

                    &:not(.btn-yellow) {
                        a {
                            margin-top: 5px;
                            padding: 8px 5px;
                            @media(min-width: 1200px) {
                                padding: 4px ​5px 12px 5p;
                            }
                        }
                    }

                    .dropdown-toggle {
                        &:after {
                            content: '\f0d7';
                            border: none;
                            font-family: 'FontAwesome';
                            vertical-align: middle;

                            @media(min-width: 1200px) {
                                display: block;
                                text-align: center;
                                position: absolute;
                                left: 0;
                                right: 0;
                            }

                            @media(max-width: 991px) {
                                display: none;
                            }
                        }

                        .glyphicon {
                            @media(max-width: 991px) {
                                display: none;
                            }

                        }
                    }


                    .sub-menu,
                    .dropdown-menu {
                        position: absolute;
                        padding: 0;
                        border-radius: 0;
                        min-width: 240px;

                        li {
                            margin-right: 0;

                            a {
                                padding: 6px 10px;
                                font-size: 14px;
                                display: block;
                                border-bottom: 1px solid rgba(color(typography, 3), 0.15);
                            }
                        }
                    }

                    &.dropdown,
                    &.menu-item-has-children {
                        position: relative;

                        &:after {
                            // @media(max-width: 991px) {
                            //     content: '\f078';
                            //     font-family: "FontAwesome";
                            //     color: color(typography, 3);
                            //     font-size: 11px;
                            // }

                        }

                        &:hover {
                            &:after {
                                @media(min-width: 992px) {
                                    color: color(typography, 2);
                                }
                            }
                        }



                        .drop-toggler {
                            color: color(typography, 3);
                            font-size: 11px;

                            @media(min-width: 992px) {
                                display: none;
                            }

                            @media(max-width: 991px) {
                                position: absolute;
                                right: 0;
                                width: 32px;
                                right: 0;
                                height: 32px;
                                line-height: 32px;
                                top: 0;
                                text-align: center;
                            }
                        }

                        &:hover {
                            .sub-menu {
                                display: block;
                            }
                        }
                    }

                }


            }

        }

        &.open {
            @media(max-width: 991px) {
                background-color: color(typography, 1);
            }
        }
    }
}

body.home-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-home>a,
body.contact-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-contact>a,
body.project-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-project>a,
body.about-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-about > a,
body.news-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-news > a,
body.service-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-service > a {
    color: color(typography, 3);
    border-bottom: 3px solid color(typography, 5);
}

body.project-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-project>a:after,
body.service-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-service > a:after {
    display: none;

}
